<template>
  <div>
    <div v-if="!showLoader">
      <m-page-title title="Amazon Transaction Detail" />

      <!-- Transaction Group Details Card -->
      <div class="card mb-3">
        <div class="card-header bg-light">
          <h5 class="mb-0">Transaction Group Details</h5>
        </div>
        <div class="card-body py-2">
          <div class="row g-2">
            <div class="col-md-4 col-lg-3">
              <div class="d-flex">
                <span class="text-muted mr-2">Group ID:</span>
                <span class="text-truncate">{{ transactionDetail.financial_event_group_id }}</span>
              </div>
            </div>
            <div class="col-md-4 col-lg-3">
              <div class="d-flex">
                <span class="text-muted mr-2">Group Start:</span>
                <span>{{ transactionDetail.financial_event_group_start }}</span>
              </div>
            </div>
            <div class="col-md-4 col-lg-3">
              <div class="d-flex">
                <span class="text-muted mr-2">Group End:</span>
                <span>{{ transactionDetail.financial_event_group_end }}</span>
              </div>
            </div>
            <div class="col-md-4 col-lg-3">
              <div class="d-flex">
                <span class="text-muted mr-2">Fund Transfer Date:</span>
                <span>{{ transactionDetail.fund_transfer_date }}</span>
              </div>
            </div>
            <div class="col-md-4 col-lg-3">
              <div class="d-flex">
                <span class="text-muted mr-2">Total Amount:</span>
                <span class="fw-bold">{{ transactionDetail.original_total_amount }}</span>
              </div>
            </div>
            <div class="col-md-4 col-lg-3">
              <div class="d-flex">
                <span class="text-muted mr-2">Currency:</span>
                <span>{{ transactionDetail.original_total_currency_code }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Transactions Table -->
      <div class="card">
        <div class="card-header bg-light d-flex justify-content-between align-items-center">
          <h5 class="mb-0">Transactions</h5>
          <button class="btn btn-sm btn-primary" @click="exportToExcel">
            <i class="fas fa-file-excel me-1"></i> Export to Excel
          </button>
        </div>
        <div class="card-body p-0">
          <div class="table-responsive">
            <table class="table table-sm table-hover mb-0">
              <thead>
                <tr>
                  <th class="ps-3">#</th>
                  <th>Posted Date</th>
                  <th>Sage Invoice #</th>
                  <th>Type</th>
                  <th>Order ID</th>
                  <th>SKU</th>
                  <th>Product Sales</th>
                  <th>Product Tax</th>
                  <th>Total</th>
                  <th>Shipping</th>
                  <th>Shipping Tax</th>
                  <th>Gift Wrap</th>
                  <th>Gift Wrap Tax</th>
                  <th>Promo</th>
                  <th>Withheld Tax</th>
                  <th>Total</th>
                  <th>Selling Fees</th>
                  <th>FBA Fees</th>
                  <th>Trans. Fees</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(transaction, index) in transactionList" :key="transaction.id">
                  <td class="ps-3">{{ index + 1 }}</td>
                  <td>{{ transaction.posted_date }}</td>
                  <td>{{ transaction.sage_reference_number }}</td>
                  <td>{{ transaction.transaction_description }}</td>
                  <td>{{ transaction.order_id }}</td>
                  <td>{{ transaction.sku }}</td>
                  <td>{{ transaction.product_charges }}</td>
                  <td>{{ transaction.our_price_tax }}</td>
                  <td>{{ transaction.product_total }}</td>
                  <td>{{ transaction.shipping_principal }}</td>
                  <td>{{ transaction.shipping_tax }}</td>
                  <td>{{ transaction.giftwrap_principal }}</td>
                  <td>{{ transaction.giftwrap_tax }}</td>
                  <td>{{ transaction.promo_rebates }}</td>
                  <td>{{ transaction.marketplace_facilitator_tax }}</td>
                  <td>{{ transaction.amazon_misc_fee_total }}</td>
                  <td>{{ transaction.amazon_fees }}</td>
                  <td>{{ transaction.fba_fees }}</td>
                  <td>{{ transaction.digital_services_fee }}</td>
                  <td>{{ transaction.total_amount }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import django_session from "@/api/django_session";
import { mapGetters } from "vuex";
import { utils, writeFile } from 'xlsx';

export default {
  name: "AmazonTransactions",

  components: {},

  data() {
    return {
      gettingData: false,
      transactionDetail: {},
      transactionList: [],
      showAlert: false,
      alertVariant: "",
      alertMessage: "",

    };
  },

  mounted() {
    document.title = "Amazon Transaction Detail";
    this.getAmazonTransactionDetail();
  },


  computed: {
    ...mapGetters("common", ["showLoader"]),
  },

  methods: {
    async getAmazonTransactionDetail() {
      this.$store.dispatch("common/showLoader", true);
      await django_session
        .get("erp/amazon/get-financial-event-group-details/" + this.$route.params.id + "/")
        .then((response) => {
          console.log(response.data);
          // if (response.data) {
            this.transactionDetail = response.data
            this.transactionList = response.data.transaction_items
            delete this.transactionDetail.transaction_items




            this.transactionDetail.financial_event_group_start = new Date(this.transactionDetail.financial_event_group_start).toUTCString();
            if (this.transactionDetail.financial_event_group_end) {
              this.transactionDetail.financial_event_group_end = new Date(this.transactionDetail.financial_event_group_end).toUTCString();
            }
            if (this.transactionDetail.fund_transfer_date) {
              this.transactionDetail.fund_transfer_date = new Date(this.transactionDetail.fund_transfer_date).toUTCString();
            }
        })
        .catch((error) => {
          if (error.response.status === 404) {
            // this.current_page = 1;
            // this.total_rows = 0;
            // this.amazonOrders = [];
            // this.getAmazonFinancialEventGroups();
          }
        })

        .finally(() => {
          this.$store.dispatch("common/showLoader", false);
        });
    },

    exportToExcel() {
      if (!this.transactionList || this.transactionList.length === 0) {
        alert("No data to export");
        return;
      }
      
      this.$store.dispatch("common/showLoader", true);
      
      try {
        // Create worksheet with transactions data - without row numbers
        const headers = [
          "Posted Date",
          "Sage Invoice #",
          "Type",
          "Order ID",
          "SKU",
          "Product Sales",
          "Product Tax",
          "Product Total",
          "Shipping",
          "Shipping Tax",
          "Gift Wrap",
          "Gift Wrap Tax",
          "Promo Rebates",
          "Withheld Tax",
          "Misc Fee Total",
          "Selling Fees",
          "FBA Fees",
          "Transaction Fees",
          "Total Amount"
        ];
        
        // Transform transaction data into rows - without row numbers
        const rows = this.transactionList.map(transaction => [
          transaction.posted_date || "",
          transaction.sage_reference_number || "",
          transaction.transaction_description || "",
          transaction.order_id || "",
          transaction.sku || "",
          transaction.product_charges || "",
          transaction.our_price_tax || "",
          transaction.product_total || "",
          transaction.shipping_principal || "",
          transaction.shipping_tax || "",
          transaction.giftwrap_principal || "",
          transaction.giftwrap_tax || "",
          transaction.promo_rebates || "",
          transaction.marketplace_facilitator_tax || "",
          transaction.amazon_misc_fee_total || "",
          transaction.amazon_fees || "",
          transaction.fba_fees || "",
          transaction.digital_services_fee || "",
          transaction.total_amount || ""
        ]);
        
        // Create transactions worksheet with headers and data
        const transactionsWs = utils.aoa_to_sheet([headers, ...rows]);
        
        // Set column widths for transactions
        transactionsWs['!cols'] = [
          { wch: 20 }, // Posted Date
          { wch: 15 }, // Sage Invoice #
          { wch: 20 }, // Type
          { wch: 20 }, // Order ID
          { wch: 15 }, // SKU
          { wch: 12 }, // Product Sales
          { wch: 12 }, // Product Tax
          { wch: 12 }, // Product Total
          { wch: 12 }, // Shipping
          { wch: 12 }, // Shipping Tax
          { wch: 12 }, // Gift Wrap
          { wch: 12 }, // Gift Wrap Tax
          { wch: 12 }, // Promo Rebates
          { wch: 12 }, // Withheld Tax
          { wch: 12 }, // Misc Fee Total
          { wch: 12 }, // Selling Fees
          { wch: 12 }, // FBA Fees
          { wch: 12 }, // Transaction Fees
          { wch: 12 }  // Total Amount
        ];
        
        // Apply conditional formatting for refund rows
        // XLSX.js doesn't support direct conditional formatting, so we need to manually apply styles
        // We'll track which rows contain refunds
        
        // const refundRows = [];
        
        // // Find rows with Type containing "Refund" (case insensitive)
        // this.transactionList.forEach((transaction, index) => {
        //   const type = transaction.transaction_description || "";
        //   if (type.toLowerCase().includes("refund")) {
        //     refundRows.push(index + 1); // +1 because row 0 is the header
        //   }
        // });
        
        // // Apply red font color to refund rows
        // if (!transactionsWs['!rows']) transactionsWs['!rows'] = [];
        // if (!transactionsWs['!cols']) transactionsWs['!cols'] = [];
        
        // // Initialize cell styles if not present
        // if (!transactionsWs['!styles']) transactionsWs['!styles'] = {};
        
        // // Apply red font to all cells in refund rows
        // refundRows.forEach(rowIndex => {
        //   // For each column in the row
        //   for (let colIndex = 0; colIndex < headers.length; colIndex++) {
        //     const cellRef = utils.encode_cell({ r: rowIndex, c: colIndex });
            
        //     // Create or update cell properties to include style
        //     if (!transactionsWs[cellRef]) {
        //       transactionsWs[cellRef] = { v: transactionsWs[cellRef], s: { font: { color: { rgb: "FF0000" } } } };
        //     } else {
        //       // If cell already exists, add style to it
        //       transactionsWs[cellRef].s = { font: { color: { rgb: "FF0000" } } };
        //     }
        //   }
        // });
        
        // Create workbook and append worksheet
        const wb = utils.book_new();
        utils.book_append_sheet(wb, transactionsWs, "Transactions");
        
        // Generate filename with group ID (shortened) and date
        const groupId = this.transactionDetail.financial_event_group_id || "";
        const shortGroupId = groupId.length > 10 ? groupId.substring(0, 10) : groupId;
        const dateStr = new Date().toISOString().split('T')[0];
        const fileName = `Amazon_Transaction_${shortGroupId}_${dateStr}.xlsx`;
        
        // Write file and download
        writeFile(wb, fileName);
      } catch (error) {
        console.error("Export failed:", error);
        alert("Export failed: " + error.message);
      } finally {
        this.$store.dispatch("common/showLoader", false);
      }
    }
  },
};
</script>

<style scoped>
.card {
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  border: none;
  border-radius: 0.375rem;
}

.card-header {
  padding: 0.75rem 1rem;
  border-bottom: 1px solid rgba(0,0,0,0.1);
}

.table-responsive {
  max-height: 70vh;
  overflow-y: auto;
}

.table {
  margin-bottom: 0;
  white-space: nowrap;
}

.table th {
  position: sticky;
  top: 0;
  background-color: #f8f9fa;
  font-size: 0.85rem;
  font-weight: 600;
  padding: 0.5rem;
  border-bottom: 2px solid #dee2e6;
  z-index: 1;
}

.table td {
  font-size: 0.85rem;
  padding: 0.4rem 0.5rem;
  vertical-align: middle;
  border-bottom: 1px solid #f0f0f0;
}

/* Ensure numerical columns are right-aligned */
.table td:nth-child(n+7):nth-child(-n+21),
.table th:nth-child(n+7):nth-child(-n+21) {
  text-align: right;
}

.text-truncate {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
}

.text-muted {
  color: #6c757d;
  font-size: 0.85rem;
}
</style>
